/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../mathapedia/css/bootstrap.css"
import "../mathapedia/css/bootstrap-offset.css"
import "../mathapedia/css/bootstrap-responsive.css"
import "../mathapedia/css/mathapedia.css"
import "../mathapedia/css/latex2js.css"

import tex from "./defn"

import { SiteMetadata } from '../components/site-metadata';

const OldLayout = ({ children }) => {
  return (
    <div role="main" id="main">
      <SiteMetadata />
      <div id="defns" style={{ display: "none" }}>
        {tex}
      </div>

      <div className="navbar navbar-fixed-top">
        <div className="navbar-inner">
          <div className="container">
            <a className="brand" href="/">
              {" "}
              mathapedia
            </a>
            <ul className="nav">
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/books">Books</a>
              </li>
              <li>
                <a href="/books/31">Paper</a>
              </li>
              <li>
                <a href="/books/34">Examples</a>
              </li>
              <li>
                <a href="/latex">Sandbox</a>
              </li>
            </ul>
          </div>

          <div id="main-editor" className="ltx-ctx"></div>
        </div>
      </div>

      <section className="container main-content" role="main">
        {children}
      </section>

      <footer className="footer">
        <div className="container">
          <p>Dan Lynch &copy; Mathapedia {new Date().getFullYear()} </p>
        </div>
      </footer>
    </div>
  )
}

// const Layout = ({ children }) => {
//   const data = useStaticQuery(graphql`
//     query SiteTitleQuery {
//       site {
//         siteMetadata {
//           title
//         }
//       }
//     }
//   `)

//   return (
//     <>
//       <Header siteTitle={data.site.siteMetadata.title} />
//       <div id="defns" style={{ display: "none" }}>
//         {tex}
//       </div>
//       <div
//         style={{
//           margin: `0 auto`,
//           maxWidth: 960,
//           padding: `0 1.0875rem 1.45rem`,
//         }}
//       >
//         <main>{children}</main>
//         <footer>
//           Dan Lynch © {new Date().getFullYear()}, Built with
//           {` `}
//           <a href="http://mathapedia.com">Mathapedia</a>
//         </footer>
//       </div>
//     </>
//   )
// }

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default OldLayout
